export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [2],
		"/about": [3],
		"/calendar": [4],
		"/faq": [5],
		"/resources": [6],
		"/resources/Kendo Japanese Pronunciation and Definitions.pdf": [7],
		"/resources/Parts of a Shinai and How To Hold It.pdf": [8],
		"/resources/kendo-beginner-guide": [9],
		"/resources/kendo-japanese-pronunciations-and-definitions": [10],
		"/resources/lineup-closing-commands": [11],
		"/resources/lineup-opening-commands": [12],
		"/resources/parts-of-a-shinai-and-how-to-hold-it": [13],
		"/resources/things-to-consider-when-buying-a-uniform": [14],
		"/resources/what-is-rei": [15]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';